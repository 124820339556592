.bg-mainBg {
  z-index: -1;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

svg:hover  {
  filter: grayscale(0)!important;
  cursor: pointer;
}
svg {
  filter: grayscale(1);
}
#layer_goal_icon svg g rect{
  border-top-right-radius: 10px;
}

.goal-wrap::-webkit-scrollbar {
  width: 5px;
}
.goal-wrap::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
}

.goal-wrap::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.goal-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tags-wrap::-webkit-scrollbar {
  width: 5px;
}
.tags-wrap::-webkit-scrollbar-track {
  background: #00000023;
  border-radius: 5px;
  cursor: pointer;
}

.tags-wrap::-webkit-scrollbar-thumb {
  background: #0000003c;
  border-radius: 5px;
}

.tags-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}